export const notice = [
    { label: "사업명" , content: "공유플랫폼기반 시민주도형 그린뉴딜" },
    { label: "전담기관" , content: "한국에너지공단" },
    { label: "주관기관" , content: "(주)에이치에너지" },
    { label: "참여기관" , content: "광명시, 광명시민전력협동조합" },
    { label: "사업내용" , content: `광명시 내 공유옥상 태양광 구축(1,000kW)\n제3자 자원연계 및 기관프로슈머전환 등 전력중개(1,700kW)\n지역 내 분산자원 운영관리, 전력중개 및 거래, 가상발전소 운영 등` },
    { label: "사업기간" , content: "2021. 04 ~ 2021. 12 (종료 시점은 연장될 수 있음)" },
]

export const disclaimer = [
    { title: "본 사업은 아래와 같이 사업 선정 및 추진절차를 거쳐 현재 진행 중입니다." , list: [
        { label: "('20년 11월 ~ '21년 1월) 공모 공고, 신청 및 접수" },
        { label: "('21년 2월) 사업 평가위원회 개최" },
        { label: "('21년 3월) 공모 선정", link: "http://www.kemco.or.kr/web/kem_home_new/info/news/notice/kem_view.asp?q=22698" },
        { label: "('21년 4월) 기관 간 협약 (한국에너지공단, 광명시 및 참여기관)" },
    ]},
    { title: "(주)에이치에너지는 주관사업자로서 관련 법령 및 규정*에 따라 본 사업의 추진 및 아래 의무를 이행하고 있습니다." , list: [
        { label: "사업계획서, 예산집행계획에 따른 사업 추진" },
        { label: "정부지원금 신청/지급/사업비 정산/사후관리" },
        { label: "사업비 산정 규모 적정성 평가를 위한 원가조사 협조" },
    ]},
    { title: "* 관련 법령 및 규정" , list: [
        { label: "지역 에너지신산업 활성화 지원사업 운용규정 (산업통상자원부)" },
        { label: "지역 에너지신산업 활성화 지원사업 사업비 관리, 사용, 정산 지침(산업통상자원부)" },
        { label: "보조금관리에 관한 법률 (제17758호)" },
        { label: "산업부 국고보조금 통합관리지침 (산업통상자원부 공고 제2020-90호)" },
    ]},
]

export const notice_detail = [
    { title: "공유옥상 태양광이란?" , content: [{ label: "협동조합이 광명시 내 유휴부지(건물 옥상 및 지붕, 주차장 등)를 임차하여 태양광발전설비 설치 후 재생에너지 생산 및 판매하고 발전수익을 나누는 사업(조합원/옥상주/지자체 등)" }] , class: "" },
    { title: "자격조건" , content: [
        { label: "광명시 관할지역 내 공유옥상 태양광 사업이 가능한 부지(건물 옥상 및 지붕)를 확보 (10kW이상 설치 가능한 공간)", class: "" },
        { label: "전기공사업면허보유", class: "" },
        { label: "상업용 태양광시공실적(500kW 이상) 증빙 필요", class: "" },
    ]},
    { title: "업무범위" , content: [
        { label: "상업용 태양광발전사업을 위한 사전 타당성 검토 (기초 설계, 현장실사 등)", class: "" },
        { label: "상업용 태양광발전사업 완공 및 사용을 위해 관련 법령에 따라 필요한 제반 업무", class: "" },
        { label: "(전기사업허가, 개발행위허가, 공사계획신고, 전력수급계약체결, 공급인증서 발급대상 설비확인, 사업개시신고 등)", class: "orange bold" },
        { label: "상업용 태양광 구조물 설치 및 전기공사", class: "" },
    ]},
    { title: "지원방법" , content: [
        { label: "아래 첨부서류를 지정된 메일로 송부", class: "" },
        { label: "01. 공유옥상 태양광 사업 대상지 관련 서류 (태양광 배치도면, 임대계약을 위한 건물사용동의서 등)", class: "orange" },
        { label: "02. 사업자등록증 1부, 전기공사업등록증 1부", class: "orange" },
        { label: "03. 지명원 1부 (태양광발전사업 시공 실적 포함)", class: "orange" },
        { label: "메일주소 : contact@henergy.xyz", class: "" },
    ]},
    { title: "참여기간" , content: [
        { label: "2022년 02월 28일까지 (사업부지 임대계약일 기준이며, 선착순 마감됩니다.)", class: "" },
    ]},
    { title: "참고사항" , content: [
        { label: "한국에너지공단 업무협약에 따라 주관사업자의 태양광발전설비 설계 기준(주관사 설계 가이드라인, 표준시방서 등), 업무 기준에 따라 진행", class: "" },
        { label: "본 사업 태양광발전시설 설치 목표용량(1,000kW) 초과시에도 주관사의 타 사업으로 배정 가능", class: "" },
    ]},
]