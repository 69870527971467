<template>
  <main>
    <section class="section section-notice">
      <div class="inner-section">
        <div class="notice-title">
          <h1>공유옥상 협력사 모집 공고</h1>
          <p>광명시와 (주)에이치에너지는 산업통상자원부 "21년 지역 에너지신산업 활성화 지원사업"에 선정되어 본 사업을 수행 중입니다.</p>
        </div>
        <div class="section-table">
          <table>
            <tbody>
              <tr v-for="no in notice_list" :key="no">
                <th>{{ no.label }}</th>
                <td>{{ no.content }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="section-list">
          <div v-for="dis in disclaimer_list" :key="dis" class="list-item">
            <h3>{{ dis.title }}</h3>
            <ul>
              <li v-for="item in dis.list" :key="item">
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="section-table">
          <div class="notice-title">
            <h3>(주)에이치에너지는 본 사업 중<br>'공유옥상 태양광'에 함께할 협력사를 모집하고 있습니다.</h3>
            <p>아래의 자격조건을 갖춘 회사는 많은 지원 바랍니다.</p>
          </div>
          <div>
          </div>
          <div class="notice-table">
              <div v-for="det in detail_list" :key="det" class="row">
                <div class="label">
                    <p class="text-bold">
                      {{ det.title }}
                    </p>
                </div>
                <div>
                    <div v-for="det_tem in det.content" :key="det_tem">
                      <p :class="det_tem.class">{{ det_tem.label }}</p>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { reactive } from "vue";
import { notice, disclaimer, notice_detail } from "./notice_info.js";
export default {
  name: "notice",
  components: {},
  setup() {
    const notice_list = reactive(notice);
    const disclaimer_list = reactive(disclaimer);
    const detail_list = reactive(notice_detail);
    return {
      notice_list,
      disclaimer_list,
      detail_list
    };
  },
};
</script>
